import React from "react";
import Helmet from "react-helmet";
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from "react-bootstrap/Nav"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar, faHome, faTools, faSign } from '@fortawesome/free-solid-svg-icons'
import Layout from "../layout";
import SEO from "../components/seo";
import peachtreeSplash from '../images/peachtree_splash_400.png'
import peachtreeLogo from '../images/peachtree_logo_300.png'
import "./index.css";

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div className="index-container">
          <Helmet title="Peachtree Asset Property Management" />
          <SEO title="Peachtree Asset Property Management" keywords={[`peachtree`, `asset`, `property`, `management`, `residential`, `commercial`]}/>

          <Navbar bg="light" expand="sm" variant="light" className="top-nav-props">
            <Container>
              <Navbar.Brand className="masthead-brand"><img src={peachtreeLogo} className="logo" alt="" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav className="nav-masthead justify-content-center">
                  <Nav.Link href="#about">About</Nav.Link>
                  <Nav.Link href="#services">Services</Nav.Link>
                  <Nav.Link href="#contact">Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {/* <!-- Header with Background Image --> */}
          <header className="business-header">
            <Container>
              <Row className="text-center">
                <Col lg={12}>
                  <img src={peachtreeSplash} className="splash mx-auto" alt="" />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mt" />
                  <h3 className="display-4 text-center text-black mt-4 heading-small">We're focused on working with you to maximize your property's value</h3>
                </Col>
              </Row>
            </Container>
          </header>

          {/* About */}
          <Container id="about">
            <Row id="contact">
              <Col md={8} xs={12}>
                <h2 class="mt-4">What We Do</h2>
                <p>Peachtree A.P.M. is a full-service firm focusing on management for both residential and commercial properties, while also providing contracting and construction services for your everyday needs and accidents.</p>
                <p>Operating with a “no job too small” policy, we’ll work with you to personalize services to your specific needs.</p>
                <p>Experienced in dealing with many different obstacles and applications, you can be sure we will can solve your issue while providing the highest tier of customer satisfaction.</p>
                <p>Contact us to find out how we can help you with management, maintenance, and everything in between.</p>
              </Col>
              <Col md={4} xs={12}>
                <h2 class="mt-4">Contact Us</h2>
                <address>
                  Audubon, PA and Jersey Shore Points
                  <br />
                </address>
                <address>
                  Call or Text at <a href="tel:484-254-6333">(484) 254-6333</a>
                  <br />
                </address>
                <address>
                  or Email at <a href="mailto:peachtreeapm@gmail.com">peachtreeapm@gmail.com</a>
                </address>

              </Col>
            </Row>
          </Container>

          {/* Services */}
          <div id="sep">
            <Container id="services">
              <Row className="justify-content-center">
                <Col md={12}>
                  <h1 className="mx-auto">Services</h1>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      Rent Collections/Evictions
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>We understand that collecting rent from your investments may be a little uncomfortable, so we provide a worry-free collection management system that is second to none when dealing with tenants. We deal with everything from the easiest tenants to evicting problematic ones, if it comes down to it. You have the ability to have immediate access to your statements regarding the properties income, valuation, deductions, expenses, and recordkeeping against your assets.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      Property Inspections/Code Evaluations
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>Keeping and eye on your assets and the regulations around them is just part of what we do. Peachtree provides monthly check-ins for your property and its needs whether you just need assurance your property isn’t taken advantage of and destroyed in the process of renting your investments out to others. We put together an organized and detailed monthly report on your assets and the updates needed to keep them within code of the state and federal laws to ensure everything runs smoothly.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      Maintenance
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>Our team problem solves to find the best solution for any issues that occur from a simple hole in the wall, to dealing with damage from natural or man-made disaster. We specialize in preventative maintenance and responding to emergency situations 24 hours a day 7 days a week, regardless of what the issue is or where it occurs. With full communication throughout between the owner, renter and management, we are there to support you and handle the situation with an appropriate response for a worry-free experience.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                      Tenant Screenings/Marketing
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>We'd like to protect your interests and make the most out of your assets so when you hand the keys to us we cover the rest. That includes finding prospective tenants and vetting them so we know what exactly what to expect from the people living in your house. We go though and research the perfect target market for the types of people you want renting. That includes criminal, background, renting history, and credit checks throughout the entire process of getting your property leased. We can provide and handle showing and marketing of the location so all you have to do is sit back and cash your checks.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="4">
                      Marina Care and Management
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>At Peachtree our services aren't just limited to land. We know owning a marina can be tough and exhausting. Schedule a meeting with us on how we can provide our high quality and tailored services to help you do less work and more siting back and collecting checks.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="5">
                      Contracting Services
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>We specialize in many versions of construction contracting with an in-person visit to figure out how to help you achieve the vision you’ve always wanted. Completing most services in-house with a great turnover rate, we can build your dream house or tear down the old one to start new. We operate with a 'no job too small' motto because we know it can be annoying to call and have someone tack on an over priced fee. The issues are ours to solve with grace and pleasure so you don’t have to worry about a thing other than how you’re going to show it off.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
          </Container>
          <br />

          {/* Contact */}
          <footer className="py-3 bg-dark">
            <Container>
              <p className="m-0 text-center text-white">Copyright &copy; {new Date().getFullYear()} Peachtree Asset Property Management; All Rights Reserved.</p>
            </Container>
          </footer>

        </div>
      </Layout>


    );
  }
}

export default Index;